import logo from './files/logoN.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        {/* <p> */}
          AGUARDEM!
        {/* </p> */}
      </header>
    </div>
  );
}

export default App;
